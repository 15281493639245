// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";

// Util
import _ZoomApi from "./Util/Zoom.api";

// Core Server
import _NotificationApi from "./Core/Notification.api";
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpApi from "./Core/Help.api";
import _ActivityLogApi from "./Core/ActivityLog.api";
import _AuditLogApi from "./Core/AuditLog.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Core/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _WorkflowTransitionAssignmentApi from "./Core/WorkflowTransitionAssignment.api";
import _PaymentApi from "./Core/Payment.api";
import _ComponentApi from "./Core/Component.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _DoptorServiceApi from "./Core/DoptorService.api";
import _ExamApi from "./Core/Exam.api";
import _FellowshipCategoryApi from "./Grant/FellowshipCategory.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

// Grant Server
import _ResearcherProfileApi from "./Grant/ResearcherProfile.api";

// Research-Journal
import _ResearchJournalApi from "./Research/ResearchJournal.api";
import _OnlineResearchJournalApi from "./Research/OnlineResearchJournalApi.api";

import _JournalArticleApi from "./Research/JournalArticleApi.api";
import _JournalNameApi from "./Research/JournalNameApi.api";
import _JournalArchiveApi from "./Research/JournalArchive.api";
import _JournalArticleInformationApi from "./Research/JournalArticleInformation.api";

// Research
import _ResearchProfileApi from "./Research/ResearchProfile.api";
import _ExperienceApi from "./Research/Experience.api";
import _DisciplineSectorIndustryApi from "./Research/DisciplineSectorIndustry.api";
import _ResearchProjectApi from "./Research/ResearchProject.api";
import _AwardsAndGrantsApi from "./Research/AwardsAndGrants.api";
import _ResearcherEducationApi from "./Research/ResearcherEducation.api";
import _ResearcherAboutApi from "./Research/ResearcherAbout.api";
import _ResearcherContactApi from "./Research/ResearcherContact.api";
import _TrainingApi from "./Research/Training.api";
import _ResearchExperienceApi from "./Research/ResearchExperience.api";
import _ResearchArticleJournalPublicationApi from "./Research/ResearchArticleJournalPublication.api";
import _ResearchFeildApi from "./Research/ResearchFeild.api";
import _ResearchLevelApi from "./Research/ResearchLevel.api";
import _JournalPublisherNameApi from "./Research/JournalPublisherName.api";
import _JournalSubjectApi from "./Research/JournalSubject.api";
import _JournalTagApi from "./Research/JournalTag.api";
import _AuthorNameApi from "./Research/AuthorName.api";
import _JournalReviewHistoryApi from "./Research/JournalReviewHistory.api";
import _WebinarExpressionOfInterestApi from "./Research/WebinarExpressionOfInterest.api";
import _WebinarEventApi from "./Research/WebinarEvent.api";
import _ResearchInterestApi from "./Research/ResearchInterest.api";
import _ResearcherPortfolioLinkApi from "./Research/ResearcherPortfolioLink.api";
import _ResearcherPublicationApi from "./Research/ResearcherPublication.api";
import _ResearcherNetworkApi from "./Research/ResearcherNetwork.api";
import _JournalArticleApplicationApi from "./Research/JournalArticleApplication.api";
import _JournalArticleApplicationInfoApi from "./Research/JournalArticleApplicationInfo";
import _WebinarNewsletterApi from "./Research/WebinarNewsletter.api";
import _WebinarRegistrationApi from "./Research/WebinarRegistration.api";
import _LiveMeetingAccountApi from "./Research/LiveMeetingAccount.api";
import _WebinarArchiveApi from "./Research/WebinarArchive.api";
import _ReportGrantApi from "./Research/ReportGrant.api";
import _ExportGrantApi from "./Research/ExportGrant.api";
import _ExportResearchApi from "./Research/ExportResearchReport.api";
import _ImportGrantApi from "./Research/ImportGrant.api";
import _PaymentHistoryApi from "./Research/PaymentHistoryApi.api";
import _WebinarTemplateApi from "./Research/WebinarTemplate.api";
import _ReportResearchApi from "./Research/ReportResearch.api";
import _WebinarTemplateVariableApi from "./Research/WebinarTemplateVariable.api";
import _NewsletterSubscriberGroupApi from "./Research/NewsletterSubscriberGroup.api";
import _AdminDashboardApi from "./Research/AdminDashboard.api";
import _SendWebinarNewsletterApi from "./Research/SendWebinarNewsletter.api";
import _WebinarArchiveSessionTimeInfoApi from "./Research/WebinarArchiveSessionTimeInfo.api";
import _ZoomAppCredentialsApi from "./Research/ZoomAppCredentials.api";
import _EnothiApi from "./Research/Enothi.api";
import _DynamicReportApi from "./Research/DynamicReportApi.api";


// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();

// UTIL Server
export const ZoomApi = new _ZoomApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();

// Grant Server
export const GrantResearcherProfileApi = new _ResearcherProfileApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const WorkflowTransitionAssignmentApi = new _WorkflowTransitionAssignmentApi();
export const PaymentApi = new _PaymentApi();
export const ComponentApi = new _ComponentApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const DoptorServiceApi = new _DoptorServiceApi();
export const ExamApi = new _ExamApi();
export const FellowshipCategoryApi = new _FellowshipCategoryApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Research Server
export const ResearchJournalApi = new _ResearchJournalApi();
export const OnlineResearchJournalApi = new _OnlineResearchJournalApi();
export const JournalArticleApi = new _JournalArticleApi();
export const ResearchArticleJournalPublicationApi = new _ResearchArticleJournalPublicationApi();
export const JournalNameApi = new _JournalNameApi();
export const ResearchFeildApi = new _ResearchFeildApi();
export const ResearchLevelApi = new _ResearchLevelApi();
export const JournalArchiveApi = new _JournalArchiveApi();
export const JournalPublisherNameApi = new _JournalPublisherNameApi();
export const JournalTagApi = new _JournalTagApi();
export const JournalReviewHistoryApi = new _JournalReviewHistoryApi();
export const ResearcherNetworkApi = new _ResearcherNetworkApi();
export const JournalArticleApplicationApi = new _JournalArticleApplicationApi();
export const JournalArticleApplicationInfoApi = new _JournalArticleApplicationInfoApi();
export const WebinarExpressionOfInterestApi = new _WebinarExpressionOfInterestApi();
export const WebinarEventApi = new _WebinarEventApi();
export const WebinarNewsletterApi = new _WebinarNewsletterApi();
export const WebinarRegistrationApi = new _WebinarRegistrationApi();
export const LiveMeetingAccountApi = new _LiveMeetingAccountApi();
export const WebinarArchiveApi = new _WebinarArchiveApi();
export const ExportGrantApi = new _ExportGrantApi();
export const ImportGrantApi = new _ImportGrantApi();
export const ReportGrantApi = new _ReportGrantApi();
export const PaymentHistoryApi = new _PaymentHistoryApi();
export const ReportResearchApi = new _ReportResearchApi();
export const ExportResearchApi = new _ExportResearchApi();
export const JournalArticleInformationApi = new _JournalArticleInformationApi();
export const NewsletterSubscriberGroupApi = new _NewsletterSubscriberGroupApi();
export const WebinarTemplateApi = new _WebinarTemplateApi();
export const WebinarTemplateVariableApi = new _WebinarTemplateVariableApi();
export const SendWebinarNewsletterApi = new _SendWebinarNewsletterApi();
export const WebinarArchiveSessionTimeInfoApi = new _WebinarArchiveSessionTimeInfoApi();
export const ZoomAppCredentialsApi = new _ZoomAppCredentialsApi();
export const DynamicReportApi = new _DynamicReportApi();

// Researcher Profile
export const ResearchProfileApi = new _ResearchProfileApi();
export const TrainingApi = new _TrainingApi();
export const ResearchExperienceApi = new _ResearchExperienceApi();
export const ResearchInterestApi = new _ResearchInterestApi();
export const ExperienceApi = new _ExperienceApi();
export const DisciplineSectorIndustryApi = new _DisciplineSectorIndustryApi();
export const AwardsAndGrantsApi = new _AwardsAndGrantsApi();
export const ResearchProjectApi = new _ResearchProjectApi();
export const ResearcherPortfolioLinkApi = new _ResearcherPortfolioLinkApi();
export const ResearcherEducationApi = new _ResearcherEducationApi();
export const ResearcherAboutApi = new _ResearcherAboutApi();
export const ResearcherContactApi = new _ResearcherContactApi();
export const ResearcherPublicationApi = new _ResearcherPublicationApi();
export const JournalSubjectApi = new _JournalSubjectApi();
export const AuthorNameApi = new _AuthorNameApi();
export const AdminDashboardApi = new _AdminDashboardApi();
export const EnothiApi = new _EnothiApi();





